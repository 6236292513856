.cintillo_expo{
background-color: #000000;
}
.texto_cintillo_expo{
  color: #ffffff;
  font-size: 46px;
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  font-weight: bold;
  margin: 0px;
}
.img_expo_mosaico{
  pointer-events: none;
  width: 100%;
}
.ponter_select{
  cursor: pointer;
}
.ponter_select_no{
  cursor:initial;
}
.img_btns_modal{
  width: 100%;
}
.empresa_select_modal{
  width: 100%;
}
.linea_modal{
  border: none;
  height: 2px;
  background-color: #ffffff;
}
.titulo_modal{
  color:#ffffff;
  margin: 10px 0px 0px 0px;
  padding: 0px;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
}
.close_modal_x{
  color:#ffffff;
  margin: 10px 0px 0px 0px;
  padding: 0px;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  font-size: 30px;
  cursor: pointer;
}
.contenido_modal{
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  color:#ffffff;
  text-align: justify;
  font-weight: bold;
}
.logo_select_modal{
  width: 50%;
}

@media (max-width:997px) {
  .texto_cintillo_expo{
    font-size: 26px;
  }
  .titulo_modal{
    font-size: 26px;
  }
  .close_modal_x{
    font-size: 20px;
  }
}