.color_naranja{
    color: #c59827;
}
.back_naranja{
    background-color: #c59827;
}
.color_azul{
color: #1d70a7;
}
.back_azul{
    background-color: #1d70a7;
}

.color_gris{
    color: #606e7a;
}
.back_gris{
    background-color: #606e7a;
} 

.color_gris_claro{
    color: #c8c9c9;
}
.back_gris_claro{
    background-color: #c8c9c9;
} 

.color_negro{
    color: #504c52;
}
.back_negro{
    background-color: #504c52;
} 
@font-face{
    font-family: 'Gotham-Black';
    src: url('../images/evento/01_iconos_Color_Tipografia/Gotham/Gotham-Black.otf');
}
@font-face{
    font-family: 'Gotham-Bold';
    src: url('../images/evento/01_iconos_Color_Tipografia/Gotham/Gotham-Bold.otf');
}
@font-face{
    font-family: 'Aileron-Bold';
    src: url('../images/evento/01_iconos_Color_Tipografia/Gotham/Gotham-Light.otf');
}
@font-face{
    font-family: 'Gotham-Medium';
    src: url('../images/evento/01_iconos_Color_Tipografia/Gotham/GothamMedium.ttf');
}
@font-face{
    font-family: 'Gotham-Pro';
    src: url('../images/evento/01_iconos_Color_Tipografia/Gotham/GothamPro.otf');
}

.Gotham_Black{
    font-family: 'Gotham-Black';
}
.Aileron_Bold{
    font-family: 'Aileron-Bold';
}
.Gotham_Medium{
    font-family: 'Gotham-Medium';
}
.Gotham_Pro{
    font-family: 'Gotham-Pro';
}
