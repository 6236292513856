.titulo_pagina_aviso{
    font-size: 30px;
}

.titulo_aviso{
    font-weight: bold;
    font-size: 20px;
    text-align: left;
}

.texto_aviso{
    font-size: 16px;
    text-align: justify;
}

