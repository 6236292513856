.titles{
  text-decoration: none;
  color: black;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 14px;
}
.registro_class{
  background-color: #1d70a7;
  padding: 8px 16px;
  color:white;
}
.contenedor_header{
  background-color: white;
    width: 100%;
    z-index:99;
    position: fixed;
}
.log_header{
  width: 150px;
}
.navbar--hidden {
  animation: desaparecer 1s;
}
@keyframes desaparecer{
  0%{display: block;}
  100%{display: none;}
}

.idioma_select{
  text-decoration: none;
  color: #39bae2;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 14px;
  cursor: pointer;
  padding: 2%;
  margin-bottom: 0px;
}
.idioma_no{
  text-decoration: none;
  color: #ffffff;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 14px;
  cursor: pointer;
  padding: 2%;
  margin-bottom: 0px;
}
@media only screen and (max-width: 767px){
  .titles{
    display:block;
    padding: 5px 0px;
  }
  .log_header{
    width: 100px;
  }
  .contenedor_header{
    background-color: white;
      width: 100%;
      z-index:99;

    position: fixed;
  }
  .idioma_select{
    font-size: 12px;
  }
  .idioma_no{
    font-size: 12px;
}
}
.seccion{
  padding: 2%;
}
StickyContainer{
  position: -webkit-sticky;
position: sticky;
top: 0;
}