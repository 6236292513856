.footer{
  background-color: #e6e6e6;
}
.logo_footer{
  width: 80%;
}
.text-blanco{
  color: #ffffff;
  text-align: left;
}
.text-blanco:hover{
  color: #ffffff;
  text-align: left;
}
.redes{
  color: #ffffff;
  font-size: 30px;
  margin: 0px 5px
}
.link-footer{
  color: #808285;
  text-decoration: none;
  text-align: left;
  font-size: 12px;
}
.link-footer:hover{
  color: #808285;
  text-decoration: none;
  text-align: left;
}
.contendores_footer{
  padding-top: 3%;
  text-align: left;
}
.titu-footer{
  color: #eb4b1c;
  font-size: 2.5em;
  text-align: left;
  font-family: 'Economica', sans-serif;
  margin-bottom: 10px;;
}
.contenedor_footer{
  background-image: url('../images/evento/05_footer_nordic/SemanaNordica_Footer.png');
  padding: 4%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: cover;
}
.btn_footer_mail{
  width: 100%;
}
.texto_footer{
  color: #ffffff;
  text-align: center;
  font-size: 28px;
  font-family: 'Gotham-Bold';
  margin:0px;
}

@media (max-width:997px) {
  .logo_footer{
    width: 80%;
  }
  .redes{
    font-size: 20px;
  }
  .texto_footer{
    color: #ffffff;
    text-align: center;
    font-size: 18px;
    font-family: 'Gotham-Bold';
    margin:0px;
  }
  
}