
.registro_exitoso{
    font-size:26px;
    text-align:center;
    margin-bottom: 0px;
    border-bottom: 1px solid #606e7a;
}

.registro_exitoso span{
    color:white;
    background-color: #606e7a;
    padding: 5px 10px;
}

.registro_exitoso_sin_linea{
    font-size:26px;
    text-align:center;
    margin-bottom: 0px;
}

.registro_exitoso_sin_linea span{
    color:white;
    background-color: #606e7a;
    padding: 5px 10px;
}


.texto_registro_exitoso{
    font-size: 14px;
    text-align: center;
}

.numero_instruccion{
    text-align:center;
}

.texto_instruccion{
    text-align:left;
    margin-left:10px !important;
}

.texto_paypal{
    font-family: 'Aileron-Regular';
    font-size: 26px;
    text-align: left;
    margin-bottom: 0px;
}
.total_paypal{
    font-family: 'Antonio-Light';
    font-size: 32px;
    text-align: center;
    font-weight: bold;
    margin-bottom: 0px;
}
.mini_texto_paypal{
    font-family: 'Aileron-Regular';
    font-size: 12px;
    text-align: center;
    margin-bottom: 0px;
}
.line_paypal{
    background-color: #21aaf7;
    border: none;
    height: 2px;
}

.icono_registro{
    width: 50px;
}

.line_paypal_rechazado{
    background-color: red;
    border: none;
    height: 2px;
}
.texto_acceso{
    font-size:14px;
    margin:0px;
    text-align: left;
}
.texto_acceso_bajo{
    font-size:18px;
    margin:0px;
}
.nota_registro_exitoso{
    padding-top:10px;
    padding-bottom:10px;
    text-align:center;
    font-size:16px;
}

.boton_inicio{
    color:white;
    border-radius:5px;
    font-size: 20px;
    background: #21aaf7;
    padding: 10px 20px;
}

.paypal-button-row.paypal-button-number-1.paypal-button-layout-vertical.paypal-button-shape-rect{
    display: none !important;
    margin-top: 1000px;
}

.contenedor_boton_ingresar{
    text-align: center;
    margin-top: 20px !important;
}

.boton_ingresar, .boton_ingresar:hover{
    color:white;
    border-radius:5px;
    font-size: 20px;
    background: #1b54af;
    padding: 10px 20px;
}
@media only screen and (max-width: 767px){
    .texto_paypal{
        font-size: 22px;
    }
    .total_paypal{
        font-family: 'Antonio-Light';
        font-size: 28px;
    }
    .mini_texto_paypal{
        font-size: 12px;
    }
}