.back_slider_premios{
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-origin: content-box;
  height: cover;
}
.texto_oro{
  color:#f1cc34;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 26px;
  margin-bottom: 0px;
}
.texto_plata{
  color:#c6c6c6;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 26px;
  margin-bottom: 0px;
}
.texto_bronce{
  color:#fc8c4f;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 26px;
  margin-bottom: 0px;
}
.texto_dec_premio{
  color:#ffffff;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 22px;
  margin-bottom: 0px;
  text-align: center;
}
.btn_premio{
  background-color: #ffffff;
  font-size: 16px;
  color: #000000;
  border-radius: 30px;
  cursor: pointer;
  font-family: Arial, Helvetica, sans-serif;
}
.img_medalla{
  width: 80%;
}
.ganadores_premio{
  color:#1d70a7;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: bold;
}
.titulo_ganador_modal{
  color:#1d70a7;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}
.modal_premio{
  width: 30%;
}
@media only screen and (max-width:767px) {
  .back_slider_premios{
    background-size: cover;
    background-repeat: no-repeat;
    height: cover;
    position: relative;
  }
  .btn_premio{
    font-size: 14px;
  }
  .img_medalla{
    width: 100%;
  }
}