body{
  font-family: 'Montserrat', sans-serif;
}
.content_wsp_fixed{
  position: fixed;
  z-index: 99;
  bottom: 20%;
  right: 0px;
}
.sticky_wsp{
  width: 100px;
}

.tituloBackstage{
  border-bottom: 4px solid #000000;
  font-size: 50px;
  font-weight: bold;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  color: #000000;
}
.img_ponente_backstage{
  width: 60%;
}
.nombre_backtage{
  color:#000000;
  font-size: 26px;
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  margin: 0px;
  font-weight: bold;
}
.ponencia_backtage{
  color:#000000;
  font-size: 12px;
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  margin: 0px;
  font-weight: bold;
}
.img_ponente_backstage_movil{
  width: 100%;
}
@media (max-width:767px) {
  .img_ponente_backstage{
    width: 90%;
  }
  .tituloBackstage{
    border-bottom: 4px solid #000000;
    font-size: 30px;
  }
  
}