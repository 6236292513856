.contenedor_ponente{
  background-image: url('');
  border-image-repeat: no-repeat;
  height: 550px;
  background-size: cover;
  padding: 1%;
}
.contenedor_ponente_movil{
  background-image: url('');
  border-image-repeat: no-repeat;
  height: 780px;
  background-size: cover;
  padding: 1%;
}
.nombre_ponente{
  text-align: left;
  color: #1d70a8;
  font-family:Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 22px;
  margin: 0px;
  text-transform: uppercase;
}
.nombre_ponente_movil{
  text-align: center;
  color: #ffffff;
  font-family: 'Antonio-Regular';
  font-size: 32px;
  margin: 0px;
}
.bio_ponente{
  text-align: justify;
  color: #ffffff;
  font-family: 'Aileron-Regular';
  font-size: 14px;
}
.bio_ponente_movil{
  text-align: justify;
  color: #ffffff;
  font-family: 'Aileron-Regular';
  font-size: 12px;
}
.img_ponentes{
  width: 100%;
}
.img_sec_ponentes{
  width: 100%;
}
.empresa_ponente{
  text-align: left;
  color: #504c52;
  font-family:Arial, Helvetica, sans-serif;
  font-size: 20px;
  margin: 0px;
}
.conferencia_ponente{
  text-align: left;
  background-color: #c59827;
  font-family:Arial, Helvetica, sans-serif;
  font-size: 20px;
  margin: 0px;
  color: #ffffff;
}
.parrafo_ponente{
  text-align: justify;
  font-family:Arial, Helvetica, sans-serif;
  font-size: 14px;
  margin: 0px;
  color: #504c52;
}
.titulo_carousel_ponente{
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
  color: #1d70a8;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
}