

.video-contenedor{
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
}
iframe, .videos{
}
.cuerpo{
  background-image: url(../images/sitio/vivo/FONDO_ENVIVO.jpg);
  border-image-repeat: repeat;
  background-size: cover;
}
.contenedor_ponentes{
  padding: 3% 0%;
  /*background-image: url('../../assets/images/temp/fondo.jpg');*/
  border-image-repeat: repeat;
  background-size: cover;
}
.contenedor_ponentes_titulo{
  padding-top: 40px;
}
.texto-preguntas{
  font-size: 0.85em;
  text-align: right;
  color: black;
  height: auto;
  line-height: 1;
  border-radius: 10px;
}
.hora-preguntas{
  font-size: 0.7em;
  text-align: right;
  margin: 0% 5%;
  padding: 0% 1% 1% 1%;
}

.ant-form-item{
  margin-bottom: 1%!important;
}
.btn-enviar{
  color: black!important;
  font-weight: bold;
  background-color: black!important;
  border-radius: 0px!important;
  font-size: 0.8em!important;
  padding: 1px 10px!important;
  border-color: white!important;
  background-color: white!important;
  
}
.ant-form-item-has-error .ant-form-item-explain, .ant-form-item-has-error .ant-form-item-split{
  color:#ffffff!important;
}
.ant-tabs-nav-wrap{
  background-color: #fe633f;
  color:#ffffff;
}
.ant-tabs-tab-btn:focus{
  color:#ffffff!important;
}
.sujeto{
  color: #ffffff;
  font-size: 10px;
  text-align: left;
}

.header-preguntas{
  font-size: 1.2em;
  padding-top: 0px;
  background-color: #1d70a7;
}
.pregunta-p{
  margin: 0px!important;
  color: white;
  padding-top:10px;
  padding-bottom:10px;
}
.img_ponente_home{
  width: 100%;
}
.contenedor_sala_text_demo{
  background-image: linear-gradient(to right, rgba(0,74,250,1), rgba(58,196,255,1));
  padding: 1%;
}
.sala_text_demo{
  color:#ffffff;
  font-size: 36px;
  text-align: center;
  font-weight: bold;
  margin:0px;
  padding:0px
}

@media screen and (min-width:900px) and (max-width:1300px){
  .pregunta-p{
    margin: 0px!important;
    color: #FFFFFF;
    padding-top:6px;
    padding-bottom:6px;
    font-size: 16px;
  }
}

.ver-preguntas{
  background-color: #f2f2f2;
  height: 330px;
  overflow: scroll;
}
.area_encuesta{
  background-color: #fe633f;
  color: #ffffff;
  font-size: 2em;
  text-align: left;
  padding: 5px 0px 0px 5px;
}
.area_resultados{
  background-color: #000000;
  color: #ffffff;
  font-size: 2em;
  text-align: left;
  padding: 5px 0px 0px 5px;
}
.text-nom, .text-empre{
  font-size: 0.75em;
  line-height: 1;
}
.preguntas-header{
  background-color: #01356f!important;

}

.contenedor_video {
	position: relative;
	padding-bottom: 56.25%; /*panorámico*/
	padding-top: 0px;
	height: 0;
}
.contenedor_video iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.video2 {
  height: 550px;
}
/*AJUSTES*/

.titulo_webinar{
  font-size: 1.8em;
  text-align: center;
  margin-bottom: 15px;
  line-height: 1;
  color: white;
}

.titulo_webinar_2{
  font-size: 0.6em;
  text-align: left;
  margin-bottom: 0px;
}
.clic_ver{
  text-align: left;
  padding-bottom: 0px;
  margin-bottom: 0px;
  font-size: 14px;
  margin-top:0px;
  padding-top: 0px;
}
.tipo_preguntas_select{
  color:white;
  background-color: #c59827;
  padding: 3px 0px 2px 0px;
  font-weight: bold;
  font-size: 12px;
  cursor: pointer;
}
.tipo_preguntas{
  color:#000000;
  background-color: #ffffff;
  padding: 3px 0px 2px 0px;
  font-weight: bold;
  font-size: 12px;
  cursor: pointer;
}
.contenedor_enviar{
  padding-top: 10px;
}
.text_enviar{
  margin-left: 0px!important;
  background-color: white!important;
  color: #083280;
}
.sujeto{
  color: #ffffff;
  font-size: 10px;
  text-align: left;
}
.balloon{
  border-radius: 5px;
  background-color: white;
  color: black;
  padding: 10px;
  width: 90%;
  margin-left:5%;
  margin-top:20px;
  margin-bottom:20px;
  display: block;
  position:relative;

  font-size: 1em;
  text-align: right;
  height: auto;
  line-height: 1;
  }

  .balloon:before {
    content:"";
    width: 0;
    height: 0;
    position: absolute;
    bottom:0px;
    right:-10px;
    border-bottom: 20px solid white !important;
    border-right: 20px solid transparent;
    border-left: 0px solid transparent;
    }
  .dia_envivo{
      background-color:#1d70a7;
      color: #ffffff;
      font-family: Arial, Helvetica, sans-serif;
      font-size: 18px;
      cursor: pointer;
      border-bottom: 2px solid #000000;
  }
.dia_envivo_select{
  background-color:#ffffff;
  color: #1d70a7;
  font-family: Arial, Helvetica, sans-serif;
      font-size: 18px;
      cursor: pointer;
      border-bottom: 2px solid #000000;
}

.conferencia_envivo{
  background-color:#1d70a7;
  color: #ffffff;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
  cursor: pointer;
}

.conferencia_envivo_row{
  background-color:#1d70a7;
}


.conferencia_envivo_select{
background-color:#ffffff;
color: #1d70a7;
font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
  cursor: pointer;
  
}


.contendor_conferencias{
  border-top: 2px solid #000000;
}
@media only screen and (min-width: 991px) {
  
  /*.imagen_logo{
    height:520px;
  }*/

}
@media only screen and (max-width: 2600px) {
  .ver-preguntas{
    height: 785px;
  }
}
@media only screen and (max-width: 2555px) {
  .ver-preguntas{
    height: 775px;
  }
}
@media only screen and (max-width: 2545px) {
  .ver-preguntas{
    height: 765px;
  }
}
@media only screen and (max-width: 2486px) {
  .ver-preguntas{
    height: 750px;
  }
}
@media only screen and (max-width: 2410px) {
  .ver-preguntas{
    height: 720px;
  }
}
@media only screen and (max-width: 2400px) {
  .ver-preguntas{
    height: 705px;
  }
  .contenedor_live{
    height: 820px;
  }
  .contenedor_live_preguntas{
    height: 820px;
  }
}
@media only screen and (max-width: 2320px) {
  .ver-preguntas{
    height: 690px;
  }
}
@media only screen and (max-width: 2305px) {
  .ver-preguntas{
    height: 680px;
  }
}
@media only screen and (max-width: 2273px) {
  .ver-preguntas{
    height: 670px;
  }
}
@media only screen and (max-width: 2230px) {
  .ver-preguntas{
    height: 650px;
  }
}
@media only screen and (max-width: 2180px) {
  .ver-preguntas{
    height: 630px;
  }
}
@media only screen and (max-width: 2120px) {
  .ver-preguntas{
    height: 610px;
  }
}
@media only screen and (max-width: 2075px) {
  .ver-preguntas{
    height: 590px;
  }
}
@media only screen and (max-width: 2030px) {
  .ver-preguntas{
    height: 580px;
  }
}
@media only screen and (max-width: 2008px) {
  .ver-preguntas{
    height: 570px;
  }
}
@media only screen and (max-width: 2000px) {
  .ver-preguntas{
    height: 560px;
  }
  .contenedor_live{
    height: 750px;
  }
  .contenedor_live_preguntas{
    height: 750px;
  }
}
@media only screen and (max-width: 1950px) {
  .ver-preguntas{
    height: 550px;
  }
}
@media only screen and (max-width: 1912px) {
  .ver-preguntas{
    height: 530px;
  }
}
@media only screen and (max-width: 1865px) {
  .ver-preguntas{
    height: 515px;
  }
}
@media only screen and (max-width: 1790px) {
  .ver-preguntas{
    height: 495px;
  }
  .contenedor_live{
    height: 700px;
  }
  .contenedor_live_preguntas{
    height: 700px;
  }
}
@media only screen and (max-width: 1756px) {
  .ver-preguntas{
    height: 480px;
  }
}
@media only screen and (max-width: 1726px) {
  .ver-preguntas{
    height: 465px;
  }
}
@media only screen and (max-width: 1706px) {
  .ver-preguntas{
    height: 455px;
  }
}
@media only screen and (max-width: 1700px) {
  .ver-preguntas{
    height: 445px;
  }
  .contenedor_live{
    height: 650px;
  }
  .contenedor_live_preguntas{
    height: 650px;
  }
}
@media only screen and (max-width: 1665px) {
  .ver-preguntas{
    height: 435px;
  }
}
@media only screen and (max-width: 1618px) {
  .ver-preguntas{
    height: 425px;
  }
}
@media only screen and (max-width: 1590px) {
  .ver-preguntas{
    height: 415px;
  }
}
@media only screen and (max-width: 1558px) {
  .ver-preguntas{
    height: 400px;
  }
  .contenedor_live{
    height: 590px;
  }
  .contenedor_live_preguntas{
    height: 590px;
  }
}
@media only screen and (max-width: 1535px) {
  .ver-preguntas{
    height: 390px;
  }
}
@media only screen and (max-width: 1490px) {
  .ver-preguntas{
    height: 380px;
  }
}
@media only screen and (max-width: 1465px) {
  .ver-preguntas{
    height: 370px;
  }
}
@media only screen and (max-width: 1450px) {
  .ver-preguntas{
    height: 360px;
  }
}
@media only screen and (max-width: 1441px) {
  .ver-preguntas{
    height: 355px;
  }
  .contenedor_live{
    height: 550px;
  }
  .contenedor_live_preguntas{
    height: 550px;
  }
}
@media only screen and (max-width: 1410px) {
  .ver-preguntas{
    height: 345px;
  }
}
@media only screen and (max-width: 1378px) {
  .ver-preguntas{
    height: 335px;
  }
}
@media only screen and (max-width: 1361px) {
  .ver-preguntas{
    height: 320px;
  }
  .contenedor_live{
    height: 520px;
  }
  .contenedor_live_preguntas{
    height: 520px;
  }
}
@media only screen and (max-width: 1318px) {
  .ver-preguntas{
    height: 310px;
  }
}
@media only screen and (max-width: 1300px) {
  .ver-preguntas{
    height: 320px;
  }
  .contenedor_live{
    height: 500px;
  }
  .contenedor_live_preguntas{
    height: 500px;
  }
}
@media only screen and (max-width: 1295px) {
  .ver-preguntas{
    height: 320px;
  }
}
@media only screen and (max-width: 1280px) {
  .ver-preguntas{
    height: 315px;
  }
}
@media only screen and (max-width: 1275px) {
  .ver-preguntas{
    height: 310px;
  }
}
@media only screen and (max-width: 1255px) {
  .ver-preguntas{
    height: 305px;
  }
}
@media only screen and (max-width: 1250px) {
  .ver-preguntas{
    height: 305px;
  }
  .contenedor_live{
    height: 480px;
  }
  .contenedor_live_preguntas{
    height: 480px;
  }
}

@media only screen and (max-width: 1230px) {
  .ver-preguntas{
    height: 295px;
  }
}
@media only screen and (max-width: 1215px) {
  .ver-preguntas{
    height: 285px;
  }
}
@media only screen and (max-width: 1200px) {
  .ver-preguntas{
    height: 285px;
  }
}
@media only screen and (max-width: 1180px) {
  .ver-preguntas{
    height: 275px;
  }
}
@media only screen and (max-width: 1161px) {
  .ver-preguntas{
    height: 270px;
  }
  .contenedor_live{
    height: 450px;
  }
  .contenedor_live_preguntas{
    height: 450px;
  }
  .header-preguntas{
    font-size: 1.4em;
  }
  .tipo_preguntas_select{
    font-size: 10px;
  }
  .tipo_preguntas{
    font-size: 10px;
  }
  .titulo_webinar{
    font-size: 1.2em;
  }
}
@media only screen and (max-width: 1145px) {
  .ver-preguntas{
    height: 260px;
  }
}
@media only screen and (max-width: 1120px) {
  .ver-preguntas{
    height: 250px;
  }
}
@media only screen and (max-width: 1095px) {
  .ver-preguntas{
    height: 243px;
  }
}
@media only screen and (max-width: 1090px) {
  .ver-preguntas{
    height: 235px;
  }
  .contenedor_live{
    height: 420px;
  }
  .contenedor_live_preguntas{
    height: 420px;
  }
  .header-preguntas{
    font-size: 1.2em;
  }
  .btn-enviar{
    font-size: 0.7em!important;
    padding: 2px 7px 2px 7px!important;
  }
  .contenedor_aniversario, .contenedor_graficas{
    height: 280px;
  }
  .imagen_logo{
    height:380px;
  }
}
@media only screen and (max-width: 1048px) {
  .ver-preguntas{
    height: 225px;
  }
}
@media only screen and (max-width: 1020px) {
  .ver-preguntas{
    height: 215px;
  }
}
@media only screen and (max-width: 991px) {
  .video{
    height: 400px;
  }
  .video2 {
    height: 350px;
  }

  .imagen_logo{
    height:350px;
  }
  .ver-preguntas{
    height: 250px;
  }
  .contenedor_live{
    height: 540px;
  }
  .contenedor_live_preguntas{
    height: 440px;
  }
  .btn-enviar{
    font-size: 0.8em!important;
    padding: 2px 10px 2px 10px!important;
    margin-bottom: 5px;
  }
  .tipo_preguntas_select{
    font-size: 12px;
  }
  .tipo_preguntas{
    font-size: 12px;
  }
  .contenedor_aniversario{
    height: 320px;
  }
  .contenedor_graficas{
    height: 260px;
  }
}
@media only screen and (max-width: 900px) {

  .contenedor_live{
    height: 490px;
  }
  .contenedor_live_preguntas{
    height: 440px;
  }
  .sala_text_demo{
    font-size: 24px;
  }
}
@media only screen and (max-width: 850px) {
  .contenedor_live{
    height: 470px;
  }
  .contenedor_live_preguntas{
    height: 440px;
  }
  .header-preguntas{
    font-size: 1.2em;
  }
}
@media only screen and (max-width: 800px) {
  .contenedor_live{
    height: 435px;
  }
  .contenedor_live_preguntas{
    height: 440px;
  }
}
@media only screen and (max-width: 750px) {
  .ver-preguntas{
    height: 250px;
  }
  .contenedor_live{
    height: 410px;
  }
  .contenedor_live_preguntas{
    height: 430px;
  }
  .header-preguntas{
    font-size: 1.2em;
  }
}
@media only screen and (max-width: 700px) {
  .contenedor_aniversario{
    height: 300px;
  }
  .contenedor_graficas{
    height: 240px;
  }
  .contenedor_live{
    height: 390px;
  }
  .contenedor_live_preguntas{
    height: 430px;
  }
}
@media only screen and (max-width: 650px) {
  .contenedor_live{
    height: 360px;
  }
  .contenedor_aniversario{
    height: 300px;
  }
  .contenedor_graficas{
    height: 240px;
  }
  .dia_envivo{
    font-size: 14px;
    line-height: 1;
    padding: 3px 0px;
}
.dia_envivo_select{
    font-size: 14px;
    line-height: 1;
    padding: 3px 0px;
}
.conferencia_envivo{
  font-size: 14px;
    line-height: 1;
    padding: 3px 0px;
  border-top: none;
}
.conferencia_envivo_select{
  font-size: 14px;
  line-height: 1;
  padding: 3px 0px;
  border-top: none;
}
}
@media only screen and (max-width: 600px) {
  .video{
    height: 300px;
  }
  .video2{
    height: 250px;
  }

  .imagen_logo{
    height:250px;
  }

  .contenedor_live{
    height: 350px;
  }
  .contenedor_live_preguntas{
    height: 430px;
  }
  .contenedor_aniversario{
    height: 280px;
  }
  .contenedor_graficas{
    height: 240px;
  }
}
@media only screen and (max-width: 550px) {
  .contenedor_live{
    height: 320px;
  }
  .contenedor_aniversario{
    height: 260px;
  }
}
@media only screen and (max-width: 500px) {
  .contenedor_live{
    height: 290px;
  }
  .titulo_webinar{
    font-size: 1em;
    text-align: left;
  }
  .pregunta-p{
    margin: 0px!important;
    color: #ffffff;
    padding-top:10px;
    padding-bottom:10px;
    font-size: 16px;
  }
}
@media only screen and (max-width: 450px) {
  .contenedor_live{
    height: 250px;
  }
  .titulo_webinar{
    font-size: 1.2em;
    text-align: left;
  }
  .clic_ver{
    font-size: 10px;
  }
  .contenedor_aniversario{
    height: 230px;
  }
}
@media only screen and (max-width: 400px) {
  .contenedor_live{
    height: 230px;
  }
  .contenedor_live_preguntas{
    height: 450px;
  }
  .ver-preguntas{
    height: 200px;
  }
  .contenedor_aniversario{
    height: 350px;
  }
}
@media screen and (max-width: 700px) {
  .nombre_tab_menu{
    font-size: 18px;
  }
}

@media screen and (min-width: 701px) and (max-width: 1000px){
  .nombre_tab_menu{
    font-size: 14px;
  }
}

@media screen and (min-width: 1001px) {
  .nombre_tab_menu{
    font-size: 12px;
  }
}

.encuesta-titulo{
  font-size: 30px;
  font-weight: bold;
}

.icono_emoji{
  width: 30px;
  cursor: pointer;
  z-index: 99;
}

@keyframes animacion {
  0%    { transform: translate(0px); width: 0px;}
  50%   { transform: translate(0px,-200px); width:60px;}
  100%  { transform: translate(00px,0px); width:0px;}
}

.contenedor_animacion{
  position: relative;
}

.animacion_emoji{
  animation-duration: 3s;
  animation-name: animacion;
  width: 0px;
  position: absolute;
}

.textos_titulos_secciones_no_olvides{
  color: #504c52;
  font-weight: bold;
  font-size: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

@media screen and (max-width: 767px){
  .ocultar_movil{
    display: none;
  }

  .conferencia_envivo_row{
    background-color:transparent;
  }

}



